import request from '@/utils/request'


// 查询研学报名列表
export function listApply(query) {
  return request({
    url: '/study/apply/list',
    method: 'get',
    params: query
  })
}

// 查询研学报名分页
export function pageApply(query) {
  return request({
    url: '/study/apply/page',
    method: 'get',
    params: query
  })
}

// 查询研学报名详细
export function getApply(data) {
  return request({
    url: '/study/apply/detail',
    method: 'get',
    params: data
  })
}

// 新增研学报名
export function addApply(data) {
  return request({
    url: '/study/apply/add',
    method: 'post',
    data: data
  })
}

// 修改研学报名
export function updateApply(data) {
  return request({
    url: '/study/apply/edit',
    method: 'post',
    data: data
  })
}

// 删除研学报名
export function delApply(data) {
  return request({
    url: '/study/apply/delete',
    method: 'post',
    data: data
  })
}
